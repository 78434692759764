import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { useField } from 'react-final-form';
import { fetchImages as fetchUnsplashImages } from 'shared/src/services/unsplash';
import { fetchImages as fetchPexelsImages } from 'shared/src/services/pexels';
import ErrorAlert from 'web-react-ui/src/chakra/ErrorAlert';
import useRequest from '../../hooks/useRequest';
import { SuggestionImageCarouselField } from './SuggestionImageCarousel';
import Suggestion from './Suggestion.interface';

const fetchImages = async (keywords: Array<string>) => {
  try {
    // Pick random keyword phrase to query
    const query = keywords[Math.floor(Math.random() * keywords.length)];
    const result = await fetchUnsplashImages({ query, limit: 3 });
    return result.items.map((image) => image.urls.raw);
  } catch (error) {
    console.error(error);
    // Separate search for each keyphrase to get more specific images
    const results = await Promise.all(
      keywords.map((query) => fetchPexelsImages({ query, limit: 1 })),
    );
    return results.map((pexelsResult) => pexelsResult.items[0].src.original);
  }
};

const SuggestedImages = ({ name, editable, suggestion }: {
  name: string,
  editable: boolean,
  suggestion: Suggestion
}) => {
  const field = useField(name, {});

  const imagesRequest = useRequest(
    async () => {
      if (!suggestion.keywords) return null;
      if (field.input.value) return null;

      const urls = await fetchImages(suggestion.keywords);

      field.input.onChange(urls);

      return urls;
    },
    [suggestion, field.input.value],
  );

  return (
    <Skeleton isLoaded={!imagesRequest.loading} borderRadius="2em">
      <ErrorAlert error={imagesRequest.error} />
      <SuggestionImageCarouselField name={name} editable={editable} />
    </Skeleton>
  );
};

export default SuggestedImages;
